import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  imgTick,
  imgTickActive
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  termsAndConditionAccepted: boolean;
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  passwordError: string;
  rePasswordError: string;
  tcError: string;
  showSuccessOverlay: boolean;
  showTermsConditionOverlay: boolean;
  showPrivacyOverlay: boolean;
  termsConditionData: string;
  privacyPolicyData: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  charReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  privacyPolicyApiCallId: string = "";
  termsConditionApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  defaultFirstName: any;
  defaultLastName: any;
  defaultLabelEmail: any;
  defaultLabelPassword: any;

  firstNameError: any;
  lastNameError: any;
  mailErrorNull: any;
  mailErrorFormat: any;
  passwordNull: any;
  passwordFormat: any;
  passwordMatch: any;

  login: string;
  signUp: string;
  skip: string;
  or: string;
  haveAnAccount: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      termsAndConditionAccepted: true,
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      passwordError: "",
      rePasswordError: "",
      tcError: "",
      showSuccessOverlay: false,
      showTermsConditionOverlay: false,
      showPrivacyOverlay: false,
      termsConditionData: "",
      privacyPolicyData: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={[}\];:'"|,.<>/?])(?=.{8,20}).+$/
    );

    this.emailReg = new RegExp(/^[a-zA-Z\d.]+@[a-zA-Z\d]+\.[a-zA-Z\d]+$/m);
    this.charReg = new RegExp(/^[a-zA-Z][a-zA-Z]*\s*$/);

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    this.defaultFirstName = configJSON.defaultFirstName;
    this.defaultLastName = configJSON.defaultLastName;
    this.defaultLabelEmail = configJSON.defaultLabelEmail;
    this.defaultLabelPassword = configJSON.defaultLabelPassword;

    this.firstNameError = configJSON.firstNameError;
    this.lastNameError = configJSON.lastNameError;
    this.mailErrorNull = configJSON.mailErrorNull;
    this.mailErrorFormat = configJSON.mailErrorFormat;
    this.passwordNull = configJSON.passwordNull;
    this.passwordFormat = configJSON.passwordFormat;
    this.passwordMatch = configJSON.passwordMatch;

    this.login = configJSON.login;
    this.signUp = configJSON.signUp;
    this.skip = configJSON.skip;
    this.or = configJSON.or;
    this.haveAnAccount = configJSON.haveAnAccount;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId &&
        responseJson &&
        apiRequestCallId === this.createAccountApiCallId &&
        !responseJson.errors
      ) {
        this.saveLoggedInUserData(responseJson);
        this.setState({ showSuccessOverlay: true });
      } else if (
        apiRequestCallId &&
        responseJson &&
        apiRequestCallId === this.privacyPolicyApiCallId
      ) {
        this.setState({
          privacyPolicyData: responseJson.data.description || ""
        });
      } else if (
        apiRequestCallId &&
        responseJson &&
        apiRequestCallId === this.termsConditionApiCallId
      ) {
        this.setState({
          termsConditionData: responseJson.data.description || ""
        });
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }
  goToPrivacyPolicy() {
    this.setState({ showPrivacyOverlay: true });
  }

  goToTermsAndCondition() {
    this.setState({ showTermsConditionOverlay: true });
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }
  isValidPassword(pass: string) {
    return this.passwordReg.test(pass);
  }
  isValidChar(data: string) {
    return this.charReg.test(data);
  }

  validateData() {
    if (this.isStringNullOrBlank(this.state.firstName)) {
      this.setState({ firstNameError: configJSON.firstNameError });
    } else if (!this.isValidChar(this.state.firstName)) {
      this.setState({ firstNameError: configJSON.firstNameCharError });
    } else {
      this.setState({ firstNameError: "" });
    }

    if (this.isStringNullOrBlank(this.state.lastName)) {
      this.setState({ lastNameError: configJSON.lastNameError });
    } else if (!this.isValidChar(this.state.lastName)) {
      this.setState({ lastNameError: configJSON.lastNameCharError });
    } else {
      this.setState({ lastNameError: "" });
    }

    if (this.isStringNullOrBlank(this.state.email)) {
      this.setState({ emailError: configJSON.mailErrorNull });
    } else if (!this.isValidEmail(this.state.email)) {
      this.setState({ emailError: configJSON.mailErrorFormat });
    } else {
      this.setState({ emailError: "" });
    }
    this.validatePasswordData();

    if (!this.state.termsAndConditionAccepted) {
      this.setState({ tcError: configJSON.termsAndConditionError });
    } else {
      this.setState({ tcError: "" });
    }
  }
  validatePasswordData() {
    if (this.isStringNullOrBlank(this.state.password)) {
      this.setState({ passwordError: configJSON.passwordNull });
    } else if (!this.isValidPassword(this.state.password)) {
      this.setState({ passwordError: configJSON.passwordFormat });
    } else {
      this.setState({ passwordError: "" });
    }

    if (this.isStringNullOrBlank(this.state.reTypePassword)) {
      this.setState({ rePasswordError: configJSON.passwordNull });
    } else if (!this.isValidPassword(this.state.reTypePassword)) {
      this.setState({ rePasswordError: configJSON.passwordFormat });
    } else if (this.state.password !== this.state.reTypePassword) {
      this.setState({ rePasswordError: configJSON.errorBothPasswordsNotSame });
    } else {
      this.setState({ rePasswordError: "" });
    }
  }

  createAccount(): boolean {
    this.validateData();
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      return false;
    }
    if (!this.isValidChar(this.state.firstName)) {
      return false;
    }
    if (!this.isValidChar(this.state.lastName)) {
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      return false;
    }

    if (!this.state.termsAndConditionAccepted) {
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.reTypePassword
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getPrivacyPolicy() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getPrivacyPolicyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiCallId = getPrivacyPolicyMsg.messageId;

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetPrivacyPolicy
    );

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPrivacyPolicyMsg.id, getPrivacyPolicyMsg);
  }

  getTermsAndCondition() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getTermsAndConditionMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsConditionApiCallId = getTermsAndConditionMsg.messageId;

    getTermsAndConditionMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetPrivacyPolicy
    );

    getTermsAndConditionMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTermsAndConditionMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getTermsAndConditionMsg.id, getTermsAndConditionMsg);
  }

  isNonNullAndEmpty(value: string) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };
  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgTermsAndConditionProps = {
    source: imgTickActive
  };

  btnTermsAndConditionProps = {
    onPress: () => {
      this.imgTermsAndConditionProps.source = !this.state
        .termsAndConditionAccepted
        ? imgTickActive
        : imgTick;
      this.setState({
        termsAndConditionAccepted: !this.state.termsAndConditionAccepted
      });
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
