import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { FlatList } from "react-native";
import moment from "moment";
import DatePicker from "react-datepicker"

// Customizable Area End

import ReservationsController, {
  Props,
  configJSON,
  Item,
} from "./ReservationsController";

export default class Reservations extends ReservationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.requestSessionData();
  }

  renderItem = (item: Item) => {
    return (
      <Box
        sx={webStyle.productContainer}
      >
        <Box
          sx={webStyle.productInfoContainerWrapper}
        >
            <img data-test-id="productImgTestID" src={item.attributes.image} style={webStyle.productImageStyle} />
          <Box>
            <Typography data-test-id="productNameTestID" variant="subtitle2">{item.attributes.service_name}</Typography>
            <Typography data-test-id="productDateTestID" variant="subtitle2">
              Date: {moment(item.attributes.reservation_date).format("DD-MMM-YYYY")}
              {" "}{item.attributes.slot_start_time}
            </Typography>
            <Typography data-test-id="productStatusTestID" variant="subtitle2">
              booking status: {item.attributes.booking_status}</Typography>
            <Typography data-test-id="productPlaceTestID" variant="subtitle2">Place: {item.attributes.city}</Typography>
          </Box>
        </Box>
        {item.attributes.booking_status !== "booked" &&
          <Box
            data-test-id="viewDetailsButton"
            component="button"
            sx={webStyle.buttonStyle}
          >
            <Button
              data-test-id="viewDetailsButtonTestID"
              color={"primary"}
              onClick={() => { this.handleViewDetails(item) }}
            >{"View Details"}</Button>
          </Box>
        }
      </Box>
    )
  }

  handleSortFilter = (value: string, sortKey: string) => {
    return (
      <Box
        data-test-id="filterButton"
        sx={webStyle.filterButton}
      >
        {this.state.order === sortKey ?
          <Box sx={webStyle.radiobuttonOuter} >
            <Box sx={webStyle.radiobuttonInner} />
          </Box>
          :
          <Box sx={webStyle.radiobuttonOuter} />
        }
        <Typography data-test-id="sortValue" variant="subtitle1" onClick={
          () => {
            this.toggleSort(sortKey)
          }}>
          {value}
        </Typography>
      </Box>
    )
  }

  handleDateFilter = (date: Date, placeholder: string, onChangeText: (date: string) => void) => {
    return (
      <DatePicker
        selected={date}
        onChange={(date:Date) =>{onChangeText(moment(date).toString())}}
        name={placeholder}
        dateFormat="MM/dd/yyyy"
      />
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapperStyle}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>
            <Input
              data-test-id="productInputTestID"
              placeholder={configJSON.search}
              value={this.state.searchText}
              type={"text"}
              inputProps={{
                inputProps: {
                  min: 0,
                  max: 100,
                  step: 1,
                },
              }}
              onChange={(event) => {
                this.handleSearch(event.target.value)
              }}
            />
            <Box
              data-test-id="filterButton"
              component="button"
              sx={webStyle.buttonStyle}
            >
              <Button
                data-test-id="filterButtonTestID"
                color={"primary"}
                onClick={() => { this.toggleFilter() }}
              >
                {this.state.isSortOpen ? "Remove Filters" : "Add Filters"}
              </Button>
            </Box>
            {this.state.isSortOpen &&
              <>
                <Box sx={webStyle.flexRow}>
                  <Typography  variant="subtitle1">{"Order By: "}</Typography>
                  {(this.handleSortFilter("Ascending order", "ascending"))}
                  {(this.handleSortFilter("Descending order", "descending"))}
                </Box>
                <Box sx={webStyle.flexRow}>
                  <Typography variant="subtitle1">{"Sort By: "}</Typography>
                  {this.handleDateFilter(
                    this.state.startDate ?new Date(this.state.startDate ) : new Date(),
                     "Enter Start Date", 
                     this.handleStartDate)}
                  {this.handleDateFilter(
                    this.state.endDate ?new Date(this.state.endDate ) : new Date(),
                    "Enter End Date", 
                    this.handleEndDate)}
                </Box>
                {this.state.endDate && this.state.startDate ?
                  <Box
                    data-test-id="dateSortButton"
                    component="button"
                    sx={webStyle.buttonStyle}
                  >
                    <Button
                      data-test-id="sortByDateButtonTestID"
                      color={"primary"}
                      onClick={() => { this.handleSortByDate() }}
                    >{"Sort By Date"}</Button>
                  </Box>
                  : <Box></Box>}
              </>}


            {this.state.itemsList?.length > 0 && (
              <FlatList
                data-test-id="productListTestID"
                data={this.state.searchedItem}
                renderItem={({ item }) => {
                  return (this.renderItem(item))
                }}
                keyExtractor={item => item.id}
              />
            )}
            <Typography
            data-test-id="historyButtonTestID"
             onClick={
              () => {
                this.btnHistoryExample();
              }
            } variant="subtitle1">{configJSON.btnHistoryExampleTitle}</Typography>
          </Box>

        </Container>

      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapperStyle: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  buttonStyle: {
    backgroundColor: "#2196F3",
    alignSelf: "flex-start",
    marginVertical: 10,
    padding: 10
  },
  productImageStyle: {
    width: "100px",
    height: "100%",
  },
  productContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "100px",
    marginBottom: "10px",
    alignItems: "center",
  },
  productInfoContainerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "center",
  },
  flexRow: { 
    flexDirection: "row", 
    marginTop: 10, 
    display: "flex", 
  },
  radiobuttonOuter: { 
    height: 15, 
    width: 15, 
    borderRadius: 15, 
    borderWidth: 1, 
    marginRight: 5, 
    alignItems: "center", 
    justifyContent: "center" 
  },
  radiobuttonInner: { 
    height: 10, 
    width: 10, 
    borderRadius: 10, 
    backgroundColor: "#2196F3" 
  },
  filterButton: { 
    flexDirection: "row", 
    marginRight: 15, 
    alignItems: "center", 
    display: "flex", 
  },
  margin10V: { 
    marginVertical: 10 
  },
  width150: { 
    width: 150 
  }
};
// Customizable Area End
