import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView
} from "react-native";
import { Overlay } from "react-native-elements";
import { imgClose, imgGoBack, imgSuccess } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID={"Background"}
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            <>
              <View>
                <View style={styles.headerView}>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.goBack();
                    }}
                  >
                    <Image
                      testID={"createAccountGoBackImage"}
                      style={styles.imagenav_ButtonImageNav}
                      source={imgGoBack}
                    />
                  </TouchableOpacity>
                  <Text style={styles.headerTextStyle}>{this.labelHeader}</Text>
                </View>

                <View>
                  <Text testID={"txtLabelFirstName"} style={styles.lableTitle}>
                    {this.labelFirstName}
                  </Text>
                  <TextInput
                    testID={"txtInputFirstName"}
                    style={
                      Platform.OS === "web" ? styles.inputWeb : styles.bgInput
                    }
                    placeholder={this.defaultFirstName}
                    value={this.state.firstName}
                    maxLength={20}
                    placeholderTextColor={"rgba(180, 180, 180, 1)"}
                    {...this.txtInputFirstNamePrpos} //Merge Engine::From BDS - {...this.testIDProps}
                  />
                  {!this.isStringNullOrBlank(this.state.firstNameError) &&
                    <Text style={styles.errorText}>{this.state.firstNameError}</Text>
                  }
                </View>

                <View>
                  <Text testID={"txtLabelLastName"} style={styles.lableTitle}>
                    {this.lastName}
                  </Text>
                  <TextInput
                    testID={"txtInputLastName"}
                    style={
                      Platform.OS === "web" ? styles.inputWeb : styles.bgInput
                    }
                    placeholder={this.defaultLastName}
                    value={this.state.lastName}
                    maxLength={20}
                    placeholderTextColor={"rgba(180, 180, 180, 1)"}
                    {...this.txtInputLastNamePrpos} //Merge Engine::From BDS - {...this.testIDProps}
                  />
                  {!this.isStringNullOrBlank(this.state.lastNameError) &&
                    <Text style={styles.errorText}>{this.state.lastNameError}</Text>
                  }
                </View>

                <View>
                  <Text testID={"txtLabelEmail"} style={styles.lableTitle}>
                    {this.labelEmail}
                  </Text>
                  <TextInput
                    testID={"txtInputEmail"}
                    style={
                      Platform.OS === "web" ? styles.inputWeb : styles.bgInput
                    }
                    placeholder={this.labelEmail}
                    value={this.state.email}
                    keyboardType={"email-address"}
                    placeholderTextColor={"rgba(180, 180, 180, 1)"}
                    {...this.txtInputEmailPrpos} //Merge Engine::From BDS - {...this.testIDProps}
                  />
                  {!this.isStringNullOrBlank(this.state.emailError) &&
                    <Text style={styles.errorText}>{this.state.emailError}</Text>
                  }
                </View>

                <View>
                  <Text testID={"txtLabelPassword"} style={styles.lableTitle}>
                    {this.labelPassword}
                  </Text>
                  <View style={styles.bgPasswordContainer}>
                    <TextInput
                      testID={"txtInputPassword"}
                      style={styles.bgPasswordInput}
                      placeholder={this.labelPassword}
                      value={this.state.password}
                      placeholderTextColor={"rgba(180, 180, 180, 1)"}
                      maxLength={20}
                      {...this.txtInputPasswordProps}
                    />

                    <TouchableOpacity
                      testID={"btnPasswordShowHide"}
                      style={styles.passwordShowHide}
                      {...this.btnPasswordShowHideProps}
                    >
                      <Image
                        testID={"imgEnablePasswordField"}
                        style={styles.imgPasswordShowhide}
                        {...this.imgEnablePasswordFieldProps}
                      />
                    </TouchableOpacity>
                  </View>
                  {!this.isStringNullOrBlank(this.state.passwordError) &&
                    <Text style={styles.errorText}>{this.state.passwordError}</Text>
                  }
                </View>

                <View>
                  <Text
                    testID={"txtLabelConfirmPassword"}
                    style={styles.lableTitle}
                  >
                    {this.labelRePassword}
                  </Text>
                  <View style={styles.bgPasswordContainer}>
                    <TextInput
                      testID={"txtInputConfirmPassword"}
                      style={styles.bgPasswordInput}
                      placeholder={this.labelRePassword}
                      value={this.state.reTypePassword}
                      placeholderTextColor={"rgba(180, 180, 180, 1)"}
                      maxLength={20}
                      {...this.txtInputConfirmPasswordProps}
                    />

                    <TouchableOpacity
                      testID={"btnConfirmPasswordShowHide"}
                      style={styles.passwordShowHide}
                      {...this.btnConfirmPasswordShowHideProps}
                    >
                      <Image
                        testID={"imgEnableRePasswordField"}
                        style={styles.imgPasswordShowhide}
                        {...this.imgEnableRePasswordFieldProps}
                      />
                    </TouchableOpacity>
                  </View>
                  {!this.isStringNullOrBlank(this.state.rePasswordError) &&
                    <Text style={styles.errorText}>{this.state.rePasswordError}</Text>
                  }
                </View>
              </View>
              <View style={styles.step2ViewBottom}>
                <View style={styles.termsAndConditionView}>
                  <TouchableOpacity {...this.btnTermsAndConditionProps}>
                    <Image
                      testID={"imgEnableRePasswordField"}
                      style={styles.tickImageStyle}
                      {...this.imgTermsAndConditionProps}
                    />
                  </TouchableOpacity>
                  <Text style={styles.termsAndConditionStyle}>
                    {`${"I agree to the"} `}
                    <Text
                      testID={"btnLegalTermsAndCondition"}
                      style={styles.btnLegalTermsAndCondition}
                      {...this.btnLegalTermsAndConditionProps}
                    >
                      {this.labelLegalTermCondition}
                    </Text>
                    {`${" and "}`}
                    <Text
                      testID={"btnLegalPrivacyPolicy"}
                      style={styles.btnLegalPrivacyPolicy}
                      {...this.btnLegalPrivacyPolicyProps}
                    >
                      {this.labelLegalPrivacyPolicy}
                    </Text>
                  </Text>
                </View>
                <View style={{ alignSelf: 'flex-start' }}>
                  {!this.isStringNullOrBlank(this.state.tcError) &&
                    <Text style={styles.errorTextTC}>{this.state.tcError}</Text>
                  }
                </View>

                <TouchableOpacity
                  testID={"btnSignUp"}
                  {...this.btnSignUpProps}
                  style={styles.signUpButtonStyle}
                >
                  <Text style={styles.signUpButtonTextStyle}>
                    {this.btnTextSignUp}
                  </Text>
                </TouchableOpacity>
                <View style={styles.orWrapper}>
                  <View style={styles.horizontalLine} />
                  <View>
                    <Text style={styles.bottomTextStyle}>{this.or}</Text>
                  </View>
                  <View style={styles.horizontalLine} />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.bottomTextStyle2}>{`${this.haveAnAccount
                    }   `}</Text>
                  <Text style={styles.bottomTextStyle3}>{this.login}</Text>
                </View>
              </View>

              <Overlay
                isVisible={this.state.showSuccessOverlay}
                onBackdropPress={() => {
                  this.setState({ showSuccessOverlay: false });
                  this.props.navigation.navigate("HomeScreen");
                }}
                overlayBackgroundColor={"rgba(64,64,64, 1)"}
                height={"28%"}
                width={"70%"}
                borderRadius={15}
              >
                <View style={styles.overlayContainer}>
                  <Image
                    testID={"imgAccountCreatedSuccessfully"}
                    style={styles.successImg}
                    source={imgSuccess}
                  />

                  <Text style={styles.successText}>
                    {"Account created Successully!"}
                  </Text>

                  <TouchableOpacity
                    style={styles.doneButtonStyle}
                    onPress={() => {
                      this.setState({
                        showSuccessOverlay: false,
                      });
                      this.props.navigation.navigate("HomeScreen");
                    }}
                  >
                    <Text style={styles.signUpButtonTextStyle}>{"Done"}</Text>
                  </TouchableOpacity>
                </View>
              </Overlay>

              <Overlay
                isVisible={this.state.showTermsConditionOverlay}
                onBackdropPress={() => {
                  this.setState({ showTermsConditionOverlay: false });
                }}
                overlayBackgroundColor={"rgba(64,64,64, 1)"}
                width={"100%"}
                height={'auto'}
                borderRadius={15}
                overlayStyle={{
                  position: "absolute",
                  bottom: 0,
                  maxHeight: "95%"
                }}
              >
                <View style={styles.overlayContainerPrivacy}>
                  <View style={{ flexDirection: 'row', borderBottomColor: 'rgba(180, 180, 180, 1)', borderBottomWidth: 1 }}>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.termsTextHeader}>
                        {"Terms & Conditions"}
                      </Text>
                    </View>
                    <TouchableOpacity onPress={() => {
                      this.setState({ showTermsConditionOverlay: false });
                    }}>
                      <Image
                        testID={"imgCloseOverlay"}
                        style={styles.closeImg}
                        source={imgClose}
                      />
                    </TouchableOpacity>
                  </View>
                  <ScrollView>
                    <View style={{ marginTop: 10 }}>
                      <Text style={styles.termsText}>{this.state.termsConditionData}</Text>
                    </View>
                  </ScrollView>
                </View>
              </Overlay>

              <Overlay
                isVisible={this.state.showPrivacyOverlay}
                onBackdropPress={() => {
                  this.setState({ showPrivacyOverlay: false });
                }}
                overlayBackgroundColor={"rgba(64,64,64, 1)"}
                width={"100%"}
                height={'auto'}
                borderRadius={15}
                overlayStyle={{
                  position: "absolute",
                  bottom: 0,
                  maxHeight: "95%"
                }}
              >
                <View style={styles.overlayContainerPrivacy}>
                  <View style={{ flexDirection: 'row', borderBottomColor: 'rgba(180, 180, 180, 1)', borderBottomWidth: 1 }}>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.termsTextHeader}>
                        {"Privacy Policy"}
                      </Text>
                    </View>

                    <TouchableOpacity onPress={() => {
                      this.setState({ showPrivacyOverlay: false });
                    }}>
                      <Image
                        testID={"imgCloseOverlay"}
                        style={styles.closeImg}
                        source={imgClose}
                      />
                    </TouchableOpacity>
                  </View>
                  <ScrollView>
                    <View style={{ marginTop: 10 }}>
                      <Text style={styles.termsText}>{this.state.privacyPolicyData}</Text>
                    </View>
                  </ScrollView>
                </View>
              </Overlay>
            </>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getPrivacyPolicy();
    this.getTermsAndCondition();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 5,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "rgba(18, 18, 18, 1)",
  },
  imagenav_ButtonImageNav: {
    width: 40,
    height: 40,
    resizeMode: "contain",
    marginRight: 25,
  },
  overlayContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  successImg: {
    width: 50,
    height: 50,
    resizeMode: "contain",
    marginVertical: 25,
  },
  tickImageStyle: {
    width: 20,
    height: 20,
    resizeMode: "contain",
    marginRight: 10,
  },
  headerTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-SemiBold",
    fontSize: 18,
  },
  lableTitle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Medium",
    fontSize: 16,
  },
  successText: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Medium",
    fontSize: 16,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 14,
    color: "#fff",
    textAlign: "left",
    backgroundColor: "rgba(64, 64, 64, 1)",
    marginTop: 14,
    marginBottom: 14,
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderRadius: 12,
    paddingLeft: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular",
  },
  errorText: {
    fontSize: 14,
    color: "red",
    textAlign: "left",
    marginTop: -5,
    marginBottom: 14,
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular",
  },
  errorTextTC: {
    fontSize: 14,
    color: "red",
    textAlign: "left",
    marginTop: -20,
    marginBottom: 14,
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular",
  },
  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderWidth: 1,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderRadius: 12,
    zIndex: -1,
    marginTop: 14,
    marginBottom: 14,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 14,
    color: "#fff",
    textAlign: "left",
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderRadius: 12,
    paddingLeft: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular",
  },
  passwordShowHide: {
    alignSelf: "center",
    marginRight: 10,
  },
  imgPasswordShowhide: { height: 16, width: 21 },

  step2ViewBottom: {
    backgroundColor: "rgba(18, 18, 18, 1)",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 20,
  },
  bottomTextStyle: {
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(246, 246, 246, 0.6)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regulr",
    fontSize: 16,
    width: 50,
  },

  bottomTextStyle2: {
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regulr",
    fontSize: 12,
  },

  bottomTextStyle3: {
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(255, 182,54, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regulr",
    fontSize: 12,
  },

  termsAndConditionStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(146, 146, 146, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: 12,
  },
  btnLegalTermsAndCondition: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: 12,
    color: "rgba(255, 182, 54,1)",
    textDecorationLine: "underline",
    borderBottomColor: "rgba(255, 182, 54, 1)",
    borderBottomWidth: 1,
  },
  btnLegalPrivacyPolicy: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: 12,
    color: "rgba(255, 182, 54,1)",
    textDecorationLine: "underline",
    borderBottomColor: "rgba(255, 182, 54, 1)",
    borderBottomWidth: 1,
  },
  signUpButtonStyle: {
    width: "100%",
    backgroundColor: "rgba(255, 182, 54, 1)",
    borderRadius: 12,
  },
  doneButtonStyle: {
    width: "100%",
    backgroundColor: "rgba(255, 182, 54, 1)",
    borderRadius: 12,
    marginTop: 25,
  },

  signUpButtonTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    color: "rgba(32, 32, 32, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-SemiBold",
    fontSize: 16,
    paddingVertical: 10,
  },

  keyboardPadding: { flex: 1 },

  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },
  headerView: {
    flexDirection: "row",
    marginBottom: 32,
    alignItems: "center",
  },
  termsAndConditionView: {
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 30,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
  },
  orWrapper: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 25,
  },
  horizontalLine: {
    flex: 1,
    height: 1,
    backgroundColor: "rgba(246, 246, 246, 0.2)",
  },
  overlayContainerPrivacy: {
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  closeImg: {
    width: 25,
    height: 25,
    resizeMode: "contain",
  },
  termsTextHeader: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    color: "#fff",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Medium",
    fontSize: 18,
    marginVertical: 15,
  },
  termsText: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "#fff",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Medium",
    fontSize: 16,
    marginVertical: 10,
  },
  // Customizable Area End
});
